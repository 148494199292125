ul.basic-list {
  font-size: 2rem;
}

.demo-spacing {
  border: 1px solid darkred;

  span {
    font-size: 2rem;
    border: 1px solid black;
  }
}