.doc-container {
  padding-top: 153px;
  padding-left: 346px;
  padding-right: 64px;
  padding-bottom: 50px;

  pre {
    font-size: 2rem;
  }

  .doc-table {
    width: 100%;
    font-size: 2rem;
  }

  .doc-table,
  .doc-table th,
  .doc-table td {
    padding: 24px;
    border: 1px solid #e2ebf8;
    border-collapse: collapse;
    text-align: left;
  }

  .doc-table-bg-color {
    background-color: #f5fafe;
  }
}
